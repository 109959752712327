<template lang="html">
  <ul
    id="nav"
    class="nav nav-tabs nav-justified bg-white-opacity-200 rounded-top"
    slot="header"
  >
    <li v-for="item in tabs">
      <router-link
        :to="{ name: item.name, params: item.params }"
        class="nav-link"
        active-class="active"
      >
        <span v-if="item.icon" :class="item.icon"></span>
        {{ item.label }}
        <span
          v-if="item.badge"
          class="badge badge-pill ml-2"
          :class="item.badge.class"
          >{{ item.badge.text }}</span
        >
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: [],
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../../scss/variables';
.nav.nav-tabs {
  border: none;
  a.nav-link {
    color: white;
    border: none !important;

    &.active {
      color: theme-color('secondary');
    }
  }
}
</style>
