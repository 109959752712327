<template lang="html">
  <transition-group
    tag="ul"
    class="nav flex-column nav-pills"
    name="list"
    appear
  >
    <p-menu-item
      v-for="item in menu"
      :item="item"
      :key="item.path"
      class="mb-1 mt-1"
    ></p-menu-item>
  </transition-group>
</template>

<script>
import MenuItem from './MenuItem'
export default {
  components: {
    'p-menu-item': MenuItem,
  },
  props: {
    menu: {
      type: Array,
      default: [],
    },
  },
}
</script>
