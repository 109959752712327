import Vue from 'vue'
import { VueEditor } from 'vue2-editor'

window.echarts = require('vue-echarts/components/ECharts').default
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/map'
import 'echarts/lib/component/tooltip'
import './plugins/echarts/theme/ds'

import Menu from './components/partials/Menu'
import UserHeader from './components/partials/UserHeader'
import TextPlaceholder from './components/partials/TextPlaceholder'
import TablePlaceHolder from './components/partials/TablePlaceHolder'
import Loading from './components/partials/Loading'
import ButtonOrderToggle from './components/partials/ButtonOrderToggle'
import PageHeader from './components/layouts/PageHeader'
import PageBody from './components/layouts/PageBody'
import PageHeaderBody from './components/layouts/PageHeaderBody'
import Paginate from './components/core/paginate/Paginate'
import Perfis from './components/partials/Perfis'

Vue.component('p-menu', Menu)
Vue.component('p-user-header', UserHeader)
Vue.component('placeholder-text', TextPlaceholder)
Vue.component('placeholder-table', TablePlaceHolder)

Vue.component('loading', Loading)
Vue.component('order-button', ButtonOrderToggle)

Vue.component('layout-header', PageHeader)
Vue.component('layout-body', PageBody)
Vue.component('layout-header-body', PageHeaderBody)

Vue.component('textarea-editor', VueEditor)

Vue.component('chart', echarts)

Vue.component('paginate', Paginate)
Vue.component('perfis', Perfis)
