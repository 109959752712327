import Vue from 'vue'
window._ = require('lodash')
window.axios = require('axios')
window.jsonp = require('jsonp')
window.moment = require('moment')
// window.Vue = require('vue')
window.swal = require('sweetalert2')
window.jsPDF = require('jspdf')
window.base64 = require('base-64')
window.md5 = require('js-md5')
window.boletoValidator = require('boleto-validator')

require('jspdf-autotable')

window.$ = window.jQuery = require('jquery')
window.Popper = require('popper.js')
require('bootstrap')
require('daterangepicker')
//window.$ui = require('jquery-ui')
require('bootstrap-datepicker')
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min')
require('jquery-validation')
require('jquery-validation/dist/additional-methods')
require('jquery-mask-plugin')

window.moment.locale('pt-br')

window.baseUrl = process.env.VUE_APP_API_URL
window.APP_VERSION = process.env.VUE_APP_BUILD_VERSION
window.ENVIRONMENT = process.env.NODE_ENV

window.axios.defaults.headers.common['Content-Type'] = 'application/json'
window.axios.defaults.headers.common['Accept'] = 'application/json'

window.swal.setDefaults({
  customClass: 'shadow',
  confirmButtonClass: 'btn btn-secondary',
  cancelButtonClass: 'btn btn-link',
  buttonsStyling: false,
  inputClass: 'form-control h-auto',
})

jQuery.validator.setDefaults({
  errorElement: 'small',
  errorClass: 'mt-2 text-danger',
  ignore: ":hidden, [contenteditable='true']",
  highlight: function (element, errorClass) {
    $(element).addClass('is-invalid')
  },
  unhighlight: function (element, errorClass) {
    $(element).removeClass('is-invalid')
  },
  success: (label, element) => {
    $(
      ' input:not(:required),select:not(:required),textarea:not(:required)'
    ).removeClass('is-invalid')
  },
  errorPlacement: function (error, element) {
    var container = $('[validate-container="' + element.attr('name') + '"]')
    if (container.length > 0) {
      error.insertAfter(container)
    } else {
      error.insertAfter(element)
    }
  },
})

jQuery.extend(jQuery.validator.messages, {
  pattern: jQuery.validator.format('O formato não corresponde ao exigido.'),
  required: 'Este campo &eacute; requerido.',
  remote: 'Por favor, corrija este campo.',
  email:
    'Por favor, forne&ccedil;a um endere&ccedil;o eletr&ocirc;nico v&aacute;lido.',
  url: 'Por favor, forne&ccedil;a uma URL v&aacute;lida.',
  date: 'Por favor, forne&ccedil;a uma data v&aacute;lida.',
  dateISO: 'Por favor, forne&ccedil;a uma data v&aacute;lida (ISO).',
  number: 'Por favor, forne&ccedil;a um n&uacute;mero v&aacute;lido.',
  digits: 'Por favor, forne&ccedil;a somente d&iacute;gitos.',
  creditcard:
    'Por favor, forne&ccedil;a um cart&atilde;o de cr&eacute;dito v&aacute;lido.',
  equalTo: 'Por favor, forne&ccedil;a o mesmo valor novamente.',
  accept:
    'Por favor, forne&ccedil;a um valor com uma extens&atilde;o v&aacute;lida.',
  maxlength: jQuery.validator.format(
    'Por favor, forne&ccedil;a n&atilde;o mais que {0} caracteres.'
  ),
  minlength: jQuery.validator.format(
    'Por favor, forne&ccedil;a ao menos {0} caracteres.'
  ),
  rangelength: jQuery.validator.format(
    'Por favor, forne&ccedil;a um valor entre {0} e {1} caracteres de comprimento.'
  ),
  range: jQuery.validator.format(
    'Por favor, forne&ccedil;a um valor entre {0} e {1}.'
  ),
  max: jQuery.validator.format(
    'Por favor, forne&ccedil;a um valor menor ou igual a {0}.'
  ),
  min: jQuery.validator.format(
    'Por favor, forne&ccedil;a um valor maior ou igual a {0}.'
  ),
})

function validateCPF(value, element) {
  value = jQuery.trim(value)
  value = value.replace('.', '')
  value = value.replace('.', '')
  var cpf = value.replace('-', '')
  while (cpf.length < 11) cpf = '0' + cpf
  var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/
  var a = []
  var b = new Number()
  var c = 11
  for (var i = 0; i < 11; i++) {
    a[i] = cpf.charAt(i)
    if (i < 9) b += a[i] * --c
  }
  var x
  if ((x = b % 11) < 2) {
    a[9] = 0
  } else {
    a[9] = 11 - x
  }
  b = 0
  c = 11
  for (var y = 0; y < 10; y++) b += a[y] * c--
  if ((x = b % 11) < 2) {
    a[10] = 0
  } else {
    a[10] = 11 - x
  }

  var retorno = true
  if (cpf.charAt(9) != a[9] || cpf.charAt(10) != a[10] || cpf.match(expReg))
    retorno = false

  return retorno
}

function validateCNPJ(value, element) {
  let cnpj = value.replace(/[^\d]+/g, '')

  if (cnpj == '') return false

  if (cnpj.length != 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(0)) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }

  let retorno = true

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(1)) retorno = false
  return retorno
}

jQuery.validator.addMethod(
  'cpf',
  function (value, element) {
    let valida = validateCPF(value, element)

    return this.optional(element) || valida
  },
  'Informe um CPF válido'
)

jQuery.validator.addMethod(
  'linhadigitavel',
  function (value, element) {
    let valida = window.boletoValidator.convenio(value)

    return this.optional(element) || valida
  },
  'Informe um CPF válido'
)

jQuery.validator.addMethod(
  'documento',
  function (value, element) {
    if (!value) {
      if (this.optional(element) || valida) {
        $(element).trigger('valid')
      } else {
        $(element).trigger('invalid')
      }
      return this.optional(element)
    }

    let valida = false

    value = value.replace(/[^\d]+/g, '')

    if (value.length && value.length > 11) {
      valida = validateCNPJ(value, element)
    } else if (value) {
      valida = validateCPF(value, element)
    }

    if (this.optional(element) || valida) {
      $(element).trigger('valid')
    } else {
      $(element).trigger('invalid')
    }

    return this.optional(element) || valida
  },
  'Informe um documento válido'
)

/*
Validator.extend('password', {
    getMessage: field => 'aaaa',
    validate: value => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.[\W]).{8,}$/.test(value)
});
Validator.extend('documento', {
    getMessage: field => 'Informe um documento válido',
    validate: (value) => {
        if(!value)
            return false;

        let valida = false;

        value = value.replace(/[^\d]+/g,'');

        if(value.length && value.length > 11){
            valida = validateCNPJ(value)
        }else if (value) {
            valida = validateCPF(value)
        }

        return valida;
    } 
});
*/

jQuery.validator.addMethod(
  'card-validade',
  function (valor, element) {
    let data = valor.split('/')

    let mes_validade = moment(_.get(data, '[0]'), 'MM')
    let ano_validade = moment(_.get(data, '[1]'), 'YY')

    return (
      this.optional(element) ||
      (mes_validade.isValid() &&
        ano_validade.isValid() &&
        `${ano_validade.format('YYYY')}-${mes_validade.format('MM')}` >=
          moment().format('YYYY-MM'))
    )
  },
  'Cartão inválido'
)

jQuery.validator.addMethod(
  'card-number',
  function (numero, element) {
    numero = _.replace(numero, /[^0-9]+/g, '')

    const bandeiras = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^[5|2][1-7][0-9]{14}/,
      diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      //    hipercard  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(50\d{2})(\d{8})(\d{4})$/,
    }

    return (
      this.optional(element) ||
      _.get(
        _.filter(bandeiras, (bandeira) => bandeira.test(numero)),
        'length',
        0
      ) > 0
    )
  },
  'Cartão inválido'
)

jQuery.validator.addMethod(
  'cep',
  (value, element) => {
    let cep = _.replace(value, /[^0-9]+/g, '')

    if (cep.length < 8) {
      return false
    }

    return true
  },
  'CEP Inválido'
)

jQuery.validator.addMethod(
  'min-date',
  function (value, element) {
    let attribute = $(element).attr('min-date')

    let format =
      $(element).attr('min-date-format') != undefined
        ? $(element).attr('min-date-format')
        : 'YYYY-MM-DD'

    if (!moment(value, format).isValid()) return false

    if (
      moment(value, format).format('YYYY-MM-DD') >=
      moment(attribute, format).format('YYYY-MM-DD')
    )
      return moment(attribute, format).format(format)

    return false
  },
  (v, o) => {
    if ($(o).attr('invalid-message')) {
      return $(o).attr('invalid-message')
    } else {
      return `A data deve ser maior ou igual há ${$(o).attr('min-date')}`
    }
  }
)

jQuery.validator.addClassRules('number-convert', {
  normalizer: function (value) {
    let re = Number(String(value).toDecimalNumber())
    return String(re)
    // debugger
    // if (value.indexOf(',') != -1 || value.indexOf('.') != -1) {
    //       return value.replace('.', '').replace(',','.')
    //   }
    //   return value
  },
})

String.prototype.toDecimalNumber = function () {
  if (this.indexOf(',') === -1 && this.indexOf('%') === -1) return Number(this)
  return Number(this.replace(/[\.\%]/g, '').replace(/\,/g, '.'))
}

Number.prototype.toRealNumber = function (decimal = 2) {
  return Number(Number(this).toFixed(decimal)).toLocaleString('pt-br', {
    minimumFractionDigits: 2,
  })
}

window.swalLoading = (title, text, icon) => {
  swal({
    title: title || 'Aguarde',
    text: text || 'Processo em Andamento',
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    buttonsStyling: true,
    onOpen: () => {},
    //type:'info',
    //imageUrl: icon || 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTBweCIgIGhlaWdodD0iNTBweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLWRvdWJsZS1yaW5nIiBzdHlsZT0iYmFja2dyb3VuZDogbm9uZTsiPjxjaXJjbGUgY3g9IjUwIiBjeT0iNTAiIG5nLWF0dHItcj0ie3tjb25maWcucmFkaXVzfX0iIG5nLWF0dHItc3Ryb2tlLXdpZHRoPSJ7e2NvbmZpZy53aWR0aH19IiBuZy1hdHRyLXN0cm9rZT0ie3tjb25maWcuYzF9fSIgbmctYXR0ci1zdHJva2UtZGFzaGFycmF5PSJ7e2NvbmZpZy5kYXNoYXJyYXl9fSIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiByPSI0MCIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2U9IiM3ZDdlN2UiIHN0cm9rZS1kYXNoYXJyYXk9IjYyLjgzMTg1MzA3MTc5NTg2IDYyLjgzMTg1MzA3MTc5NTg2IiB0cmFuc2Zvcm09InJvdGF0ZSgzMS40Mjg2IDUwIDUwKSI+PGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGNhbGNNb2RlPSJsaW5lYXIiIHZhbHVlcz0iMCA1MCA1MDszNjAgNTAgNTAiIGtleVRpbWVzPSIwOzEiIGR1cj0iMi4xcyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPjwvY2lyY2xlPjxjaXJjbGUgY3g9IjUwIiBjeT0iNTAiIG5nLWF0dHItcj0ie3tjb25maWcucmFkaXVzMn19IiBuZy1hdHRyLXN0cm9rZS13aWR0aD0ie3tjb25maWcud2lkdGh9fSIgbmctYXR0ci1zdHJva2U9Int7Y29uZmlnLmMyfX0iIG5nLWF0dHItc3Ryb2tlLWRhc2hhcnJheT0ie3tjb25maWcuZGFzaGFycmF5Mn19IiBuZy1hdHRyLXN0cm9rZS1kYXNob2Zmc2V0PSJ7e2NvbmZpZy5kYXNob2Zmc2V0Mn19IiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHI9IjM1IiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZT0iIzFhMWIxYiIgc3Ryb2tlLWRhc2hhcnJheT0iNTQuOTc3ODcxNDM3ODIxMzggNTQuOTc3ODcxNDM3ODIxMzgiIHN0cm9rZS1kYXNob2Zmc2V0PSI1NC45Nzc4NzE0Mzc4MjEzOCIgdHJhbnNmb3JtPSJyb3RhdGUoLTMxLjQyODYgNTAgNTApIj48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgY2FsY01vZGU9ImxpbmVhciIgdmFsdWVzPSIwIDUwIDUwOy0zNjAgNTAgNTAiIGtleVRpbWVzPSIwOzEiIGR1cj0iMi4xcyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPjwvY2lyY2xlPjwvc3ZnPg=='
  })
  swal.showLoading()
}

export default Vue
