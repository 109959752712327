<template>
  <treeselect
    class="shadow-sm"
    :options="perfisTree"
    v-model="perfil"
    :multiple="multiple"
    :placeholder="placeholder"
    @input="change"
    :name="name"
    :instanceId="name"
    :required="required"
    :load-options="loadOptions"
    value-consists-of="ALL_WITH_INDETERMINATE"
    :disabled="disabled"
  >
  </treeselect>
</template>
<script>
import { Treeselect, LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
export default {
  props: {
    perfilId: {
      default: '',
    },
    placeholder: {
      default: 'Perfil',
    },
    tipo: {
      default: '',
    },
    required: {
      default: '',
    },
    multiple: {
      default: false,
    },
    name: {
      default: 'perfil',
    },
    disabled: {
      default: false,
    },
  },
  components: { Treeselect },
  data() {
    return {
      perfisTree: null,
      perfil: null,
    }
  },
  watch: {
    tipo() {
      this.fetchPerfis().then((r) => {
        this.perfisTree = this.mapPerfil(this.$(r.data, 'itens', []))
      })
    },
    perfilId() {
      this.perfil = this.perfilId ? this.perfilId : undefined
    },
  },
  methods: {
    async loadOptions(a) {
      if (a.action === LOAD_ROOT_OPTIONS) {
        this.fetchPerfis().then((r) => {
          this.perfisTree = this.mapPerfil(this.$(r.data, 'itens', []))
        })
      }
    },
    fetchPerfis() {
      return this.$http.get('perfis', {
        params: {
          paginacao: 'nao',
          tipo: this.tipo,
        },
      })
    },
    mapPerfil(perfis) {
      return _.map(perfis, (p) => {
        let children = this.mapPerfil(this.$(p, 'perfis', null))
        let ret = {
          id: p.id,
          label: p.nome,
        }

        if (this.$(children, 'length', 0) > 0) {
          ret.children = children
        }

        return ret
      })
    },
    change() {
      this.$emit('change', this.perfil)
    },
  },
  created() {
    if (this.perfilId) this.perfil = this.perfilId
  },
}
</script>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
<style>
.vue-treeselect--single .vue-treeselect__input {
  height: auto;
}
</style>
