import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { setupI18n } from '@lingui/core'

Vue.use(VeeValidate)

// https://lingui.js.org/ref/core.html
const i18n = setupI18n({
  language: 'pt',
  catalogs: {
    ar: {
      messages: {
        'validation.messages.required': `هذا الحقل مطلوب`,
        'validation.messages.email': `هذا الحقل يجب ان يكون ايميل صحيح.`,
        'validation.attributes.email': `البريد الاليكتروني`,
      },
    },
    en: {
      messages: {
        'validation.messages.required': `This field is required.`,
        'validation.messages.email': `This field must be a valid email.`,
        'validation.attributes.email': `Email Address`,
      },
    },
    pt: {
      messages: {
        'validation.messages.required': `Este campo é obrigatório`,
        'validation.messages.email': `Informe um email válido`,
        'validation.attributes.email': `Endereço de email`,
      },
    },
  },
})

// https://github.com/baianat/vee-validate/blob/master/flow/dictionary.js#L12
const linguiDictionary = {
  get locale() {
    return i18n._language
  },
  set locale(val) {
    i18n.activate(val)
  },
  getMessage(_, key, __) {
    const id = `validation.messages.${key}`

    return i18n._(id)
  },
  setMessage(locale, key, value) {
    i18n.load({
      [locale]: {
        messages: {
          [`validation.messages.${key}`]: value,
        },
      },
    })
  },
  getAttribute(_, key) {
    const id = `validation.attributes.${key}`

    return i18n._(id)
  },
  setAttribute(locale, attr, value) {
    i18n.load({
      [locale]: {
        messages: {
          [`validation.attributes.${attr}`]: value,
        },
      },
    })
  },
  getFieldMessage(_, field, rule, data) {
    const id = `validation.custom.${field}.${rule}`

    let msg = i18n._(id, {}, { defaults: '😝' })
    if (msg !== '😝') {
      return msg
    }

    return this.getMessage(_, rule, data)
  },
  merge(dictionary) {
    i18n.load(dictionary)
  },
  getDateFormat(locale) {
    // TODO
  },
  setDateFormat(locale, format) {
    // TODO
  },
}

VeeValidate.setI18nDriver('lingui', linguiDictionary)

export default VeeValidate
