<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import sassStyles from '@/scss/app.scss'
export default {
  methods: {
    requestInterceptors() {
      this.$http.defaults.baseURL = process.env.VUE_APP_API_URL
      this.$http.interceptors.response.use(null, (event, status) => {
        let errorStatus = [400, 404]
        if (
          this.$router.currentRoute.name != 'login' &&
          event.response &&
          event?.response?.status == 401
        ) {
          return this.$router.push({ name: 'login' })
        }
        if (
          event.response &&
          errorStatus.indexOf(event?.response?.status) != -1 &&
          event.response.data.message != undefined
        ) {
          swal({
            type: 'error',
            title: event.response.data.title ? event.response.data.title : '',
            text: event.response.data.message,
          })
        }
        return Promise.reject(event)
      })
    },
  },
  computed: {
    loading() {
      return this.$root.loading
    },
  },
  created() {
    if (!this.$cookie.get('authsession') && this.$route.name != 'login') {
      this.$router.push({ name: 'login' })
    } else {
      this.requestInterceptors()
    }
  },
}
</script>
