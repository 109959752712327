<template>
  <span v-html="placeholderElement"></span>
</template>

<script>
export default {
  props: {
    tag: {
      default: 'span',
    },
    minWidth: {
      default: 60,
    },
    minHeight: {
      default: 'auto',
    },
  },
  computed: {
    placeholderElement() {
      let width =
        typeof this.minWidth == 'number' ? this.minWidth + '%' : this.minWidth
      let height =
        typeof this.minHeight == 'number'
          ? this.minHeight + '%'
          : this.minHeight
      return (
        '<' +
        this.tag +
        ' class="placeholder rounded m-0 p-0" style="min-width:' +
        width +
        ';min-height:' +
        height +
        '">&nbsp</' +
        this.tag +
        '>'
      )
    },
  },
}
</script>

<style lang="scss">
@keyframes placeHolderShimmer {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
.placeholder {
  display: inline-block;
  // background-color: transparentize(black, 0.9);
  animation-duration: 40s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    transparentize(black, 0.95) 8%,
    transparentize(black, 0.9) 18%,
    transparentize(black, 0.95) 33%
  );
}
</style>
