import Vue from 'vue'
import Vuex from 'vuex'
import telemarketing from './stores/telemarketing'

Vue.use(Vuex)

const $$store = new Vuex.Store({
  modules: {
    telemarketing,
  },
})

export default $$store
