<template lang="html">
  <div class="mb-5 mt-2 text-white">
    <div class="row align-items-center align-content-between mb-2">
      <div class="col">
        <h3 class="text-white d-flex align-items-center">
          <i class="mr-4 align-middle" v-if="icon" :class="icon"></i>
          {{ title }}
        </h3>
      </div>
      <div class="col-auto text-center" v-for="i in menu">
        <template
          v-if="_.get(i, 'callback') && typeof i.callback == 'function'"
        >
          <a href @click.prevent="i.callback" class="text-white">
            <i :class="i.icon" class="la-2x"></i><br />
            {{ i.name }}
          </a>
        </template>
        <router-link
          v-else-if="$root.checkPermissionByKey({ path: i.path })"
          :to="{ path: i.path }"
          class="text-white"
        >
          <i :class="i.icon" class="la-2x"></i><br />
          {{ i.name }}
        </router-link>
      </div>
    </div>
    <ol class="breadcrumb bg-transparent p-0" v-if="breadcrumb.length > 0">
      <li class="breadcrumb-item" aria-current="page">
        <i class="fa fa-home"></i>
      </li>
      <template v-for="(i, index) in breadcrumb">
        <li
          v-if="i.path"
          class="breadcrumb-item"
          :class="{ active: index == breadcrumb.length - 1 }"
        >
          <router-link class="text-white" :to="{ path: i.path }">{{
            i.name
          }}</router-link>
        </li>
        <li
          v-if="!i.path"
          class="breadcrumb-item"
          :class="{ active: index == breadcrumb.length - 1 }"
        >
          {{ i.name }}
        </li>
      </template>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Titulo',
    },
    icon: {
      type: String,
      default: '',
    },
    breadcrumb: {
      type: Array,
      default: () => {
        return []
      },
    },
    menu: {
      type: Array,
      default: () => {
        return
      },
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1)
    },
  },
}
</script>
