<template>
  <tbody>
    <tr v-for="n in rows" v-bind:key="n">
      <td v-for="k in cols" v-bind:key="k">
        <placeholder-text
          class="d-block"
          tag="small"
          :min-width="rangeSize()"
        ></placeholder-text>
      </td>
    </tr>
  </tbody>
</template>
<script>
export default {
  props: {
    rows: {
      default: 5,
    },
    cols: {
      default: 5,
    },
  },
  methods: {
    rangeSize() {
      return _.random(50, 95, false)
    },
  },
}
</script>
