<template>
  <a href @click.prevent="toggle">
    <slot></slot>
    <small
      ><i
        class="la"
        :class="{
          'la-angle-down': sort == 'DESC',
          'la-angle-up': sort == 'ASC',
        }"
        v-if="sort != null"
      ></i
    ></small>
  </a>
</template>

<script>
export default {
  props: ['fieldName', 'currentField', 'currentSort'],
  data() {
    return {
      sort: null,
    }
  },
  methods: {
    toggle() {
      this.sort = String(this.sort).toLowerCase() == 'asc' ? 'DESC' : 'ASC'
      this.$root.$emit('button.order.toggle', {
        field: this.fieldName,
        sort: this.sort,
      })
      this.$emit('toggle', {
        field: this.fieldName,
        sort: this.sort,
      })
    },
  },
  created() {
    if (this.fieldName == this.currentField) this.sort = this.currentSort

    this.$root.$on('button.order.toggle', (event) => {
      if (event.field != this.fieldName) this.sort = null
    })
  },
}
</script>
