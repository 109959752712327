export default [
  {
    name: 'Cadastro',
    description: 'Efetuar ou possuir cadastro de doador',
    key: 'a-cadastro',
    depends: [],
    component: () => import('./ACadastro'),
    callbacks: {
      success: 'Colaborador cadastrado',
    },
  },
  {
    name: 'Atualização de Cadastro',
    description: 'Atualizar cadastro de doador',
    key: 'a-atualizacao-cadastro',
    depends: [],
    component: () => import('./AAtualizarCadastro'),
    callbacks: {
      success: 'Cadastro atualizado',
    },
  },
  {
    name: 'Pendências de pagamento',
    description: 'Enviar notificações de pendências em aberto de colaborador',
    key: 'a-pendencias',
    depends: ['a-cadastro'],
    component: () => import('./APendencias'),
    callbacks: {
      success: 'Notificações enviadas',
    },
  },
]
