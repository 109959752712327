<template>
  <div>
    <div v-if="hasFloat">
      <slot name="float"></slot>
    </div>
    <div class="card bg-white-opacity-200 rounded shadow">
      <div v-if="hasHeader" class="card-header p-0">
        <slot name="header"></slot>
      </div>
      <div
        v-if="hasDefault"
        class="card-body bg-white p-2"
        :class="{
          'rounded-top': !hasHeader && roundedTop,
          'rounded-bottom': !hasTable && roundedBottom,
        }"
      >
        <slot></slot>
      </div>
      <div
        v-if="hasTable"
        class="bg-white rounded-bottom"
        :class="{ 'rounded-top': !hasHeader && !hasDefault && roundedTop }"
      >
        <slot name="table"></slot>
      </div>
    </div>
    <template v-if="hasFloatEnd">
      <slot name="floatend"></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    paginate: {
      default: null,
    },
    roundedTop: {
      default: true,
    },
    roundedBottom: {
      default: true,
    },
  },
  computed: {
    hasFloat() {
      return this.$slots.float != undefined
    },
    hasFloatEnd() {
      return this.$slots.floatend != undefined
    },
    hasHeader() {
      return this.$slots.header != undefined
    },
    hasDefault() {
      return this.$slots.default != undefined
    },
    hasTable() {
      return this.$slots.table != undefined
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../../scss/variables';
.table {
  margin-bottom: 0;
}

.nav.nav-tabs {
  border: none;
  a.nav-link {
    color: white;
    border: none !important;

    &.active {
      color: theme-color('secondary');
    }
  }
}
</style>
