import AcoesList from '@/js/components/views/telemarketing/partials/acoes'
export default {
  state: {
    hash: null,
    contato: null,
    acoes: [],
  },
  mutations: {
    SET_HASH(state, payload) {
      state.hash = payload
    },
    SET_CONTATO(state, payload) {
      state.contato = payload
    },
    SET_MENSAGENS(state, payload) {
      state.contato.mensagens = payload
    },
    SET_ACOES(state, payload) {
      state.acoes = payload
    },
    SET_ATIVO(state, payload) {
      let acao = _.find(state.acoes, (f) => {
        return f.componente_item_key == payload.componente_item_key
      })
      if (acao != undefined) acao.ativo = payload.ativo
    },
    SET_VISIBILIDADE(state, payload) {
      let acao = _.find(state.acoes, (f) => {
        return f.componente_item_key == payload.componente_item_key
      })
      if (acao != undefined) acao.visivel = payload.visivel
    },
    SET_STATUS(state, payload) {
      let acao = _.find(state.acoes, (f) => {
        return f.componente_item_key == payload.componente_item_key
      })
      if (acao != undefined) acao.status = payload.status
    },
  },
  actions: {
    setContato({ commit }, hash) {
      axios.get('telemarketing/contato/' + hash).then((event) => {
        if (event && event.status == 200) {
          commit('SET_HASH', hash)
          commit('SET_CONTATO', event.data)
        }
      })
    },
    updateMensagensContato({ commit }, hash) {
      axios
        .get('telemarketing/contato/' + hash + '/mensagens')
        .then((event) => {
          if (event && event.status == 200) {
            commit('SET_MENSAGENS', event.data)
          }
        })
    },
    setAcoes({ commit }, payload) {
      let acoes = _.map(payload, (m) => {
        let completed =
          _.find(payload, (f) => {
            return f.componente_item_key == m.componente_item_key
          }) != undefined
        let action = _.find(AcoesList, (f) => {
          return f.key == m.componente_item_key
        })
        return {
          componente_item_key: m.componente_item_key,
          descricao: m.descricao,
          nome: m.nome,
          obrigatorio: m.obrigatorio,
          status: false,
          callbacks: action.callbacks,
          ativo: false,
          visivel: false,
        }
      })
      commit('SET_ACOES', acoes)
    },
    setVisibilidade({ commit }, payload) {
      commit('SET_VISIBILIDADE', payload)
    },
    setAtivo({ commit }, payload) {
      commit('SET_ATIVO', payload)
    },
    setStatus({ commit }, payload) {
      commit('SET_STATUS', payload)
    },
  },
  getters: {
    $contato: (state) => state.contato,
    $acoes: (state) => state.acoes,
    $hash: (state) => state.hash,
  },
}
