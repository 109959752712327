<template>
  <div class="media text-white mb-4 mt-1 d-flex" v-if="user">
    <img
      class="align-self-center mr-3 rounded-circle bg-opacity shadow"
      src="@/img/avatar-default.svg"
      height="50"
      alt=""
    />
    <div class="media-body align-self-center">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="mt-0 mb-1">{{ user.nome }}</h6>
          <a class="text-white" href @click.prevent="logout"
            ><small>sair</small></a
          >
        </div>
        <div
          v-if="$root.checkPermissionByKey({ name: 'notificacoes.tela' })"
          class="col-auto"
        >
          <a
            @hidden="checkNotificacoes()"
            v-popover="optionsPopover"
            ref="notificacoesBtn"
            @click.prevent=""
            href="#"
            class="text-white"
            ><i class="fa fa-bell-o"></i
            ><sup
              v-if="totalNotificacoes > 0"
              class="badge badge-danger badge-pill shadow"
              >{{ totalNotificacoes }}</sup
            ></a
          >
          <div ref="notificacoesContent" class="notificacoesContent">
            <table class="table table-hover table-striped mb-0">
              <tr
                v-for="n in notificacoes"
                :class="{ 'table-warning': n.status.id == 1 }"
              >
                <td>
                  <div>{{ n.descricao }}</div>
                  <div class="text-right text-muted">
                    {{ moment(n.data_criacao).fromNow() }}
                  </div>
                </td>
              </tr>
              <tr v-if="notificacoes.length == 0">
                <td class="text-muted">Nenhuma notificação no momento</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-auto text-nowrap">
          <div class="dropdown">
            <a
              href="#"
              data-toggle="dropdown"
              data-placement="body"
              aria-expanded="false"
            >
              <i class="fa fa-cog text-white"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-right bg-white-opacity-200">
              <li>
                <router-link
                  :to="{ name: 'auth.alterarSenha' }"
                  class="dropdown-item"
                  >Alterar senha</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notificacoes: [],
    }
  },
  computed: {
    totalNotificacoes() {
      return _.filter(this.notificacoes, (f) => {
        return Number(f.status.id) == 1
      }).length
    },
    cliente() {
      return this.$root.cliente
    },
    user() {
      return this.$root.user
    },
    optionsPopover() {
      return {
        trigger: 'focus',
        html: true,
        placement: 'bottom',
        title: 'Notificações',
        template:
          '<div class="popover border-0 shadow-lg" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body p-0"></div></div>',
        content: () => {
          return this.$refs.notificacoesContent
        },
      }
    },
  },
  methods: {
    logout() {
      this.$root.logout()
    },
    checkNotificacoes() {
      let notificacoes = _.filter(this.notificacoes, (f) => {
        return f.status.id == 1
      })
      _.each(notificacoes, (e) => {
        this.$http
          .put(`notificacoes/${e.id}`, {
            status: 'lido',
          })
          .then((event) => {
            if (event && event.status == 200) {
              this.fetchNotificacoes()
            }
          })
      })
    },
    fetchNotificacoes() {
      if (!this.$root.checkPermissionByKey({ name: 'notificacoes.tela' }))
        return

      this.$http
        .get('notificacoes', {
          params: {
            paginacao: 'nao',
            tipo: 'sistema',
          },
        })
        .then((event) => {
          if (event && event.status == 200) {
            this.notificacoes = event.data.itens
          }
        })
    },
  },
  created() {
    setInterval(() => {
      if (this.$cookie.get('authsession')) {
        this.fetchNotificacoes()
      }
    }, 60000)
  },
}
</script>
<style>
.notificacoesContent {
  display: none;
}
.popover .notificacoesContent {
  display: block;
}
.popover-body {
  padding: 0;
  max-height: 60vh;
  overflow-y: auto;
}
</style>
