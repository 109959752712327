<template>
  <div class="d-table mx-auto text-muted text-center">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Carregando...',
    },
  },
}
</script>
