<template>
  <ul class="pagination mb-0" v-if="data.total_itens > data.limite">
    <li class="page-item pagination-prev-nav" v-if="data.pagina_atual > 1">
      <a
        class="page-link"
        href="#"
        aria-label="Previous"
        @click.prevent="selectPage(data.pagina_atual - 1)"
      >
        <slot name="prev-nav">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </slot>
      </a>
    </li>
    <li
      class="page-item pagination-page-nav"
      v-for="n in getPages()"
      :class="{ active: n == data.pagina_atual }"
    >
      <a class="page-link" href="#" @click.prevent="selectPage(n)">{{ n }}</a>
    </li>
    <li
      class="page-item pagination-next-nav"
      v-if="data.pagina_atual < data.ultima_pagina"
    >
      <a
        class="page-link"
        href="#"
        aria-label="Next"
        @click.prevent="selectPage(data.pagina_atual + 1)"
      >
        <slot name="next-nav">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </slot>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          pagina_atual: 1,
          itens: [],
          primeira_anterior: 1,
          ultima_pagina: 1,
          limite: 10,
          proxima_pagina: 1,
          total_itens: 0,
        }
      },
    },
    maximoVisivel: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    selectPage: function (page) {
      if (page === '...') {
        return
      }
      this.$emit('change', page)
    },
    getPages: function () {
      if (this.maximoVisivel === -1) {
        return 0
      }

      if (this.maximoVisivel === 0) {
        return this.data.ultima_pagina
      }

      var current = this.data.pagina_atual,
        last = this.data.ultima_pagina,
        delta = this.maximoVisivel,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        pages = [],
        l

      for (var i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i)
        }
      }

      range.forEach(function (i) {
        if (l) {
          if (i - l === 2) {
            pages.push(l + 1)
          } else if (i - l !== 1) {
            pages.push('...')
          }
        }
        pages.push(i)
        l = i
      })

      return pages
    },
  },
}
</script>
