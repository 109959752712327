import Vue from 'vue'
Vue.filter('number', function (value = 0, d) {
  var decimal = !d && d !== 0 ? 2 : d
  if (decimal == 0) return Number(value || 0).toFixed(0)
  return Number(Number(value || 0).toFixed(decimal)).toLocaleString('pt-br', {
    minimumFractionDigits: 2,
  })
})
Vue.filter('moment', function (value, formated, format) {
  if (value == null) return ''
  return moment(value, format).format(formated)
})
Vue.filter('testeee', function (value, formated, format) {
  if (value == null) return ''
  return moment(value, format).format(formated)
})
Vue.filter('capitalize', function (value) {
  return _.capitalize(value)
})
Vue.filter('uppercase', function (value) {
  return _.upperCase(value)
})
Vue.filter('lowercase', function (value) {
  return _.lowerCase(value)
})
Vue.filter('phone-format', function (value) {
  let numberBase = _.replace(value, /[^0-9]+/g, '')
  switch (numberBase.length) {
    case 11:
      return (
        '(' +
        String(numberBase).substr(0, 2) +
        ')' +
        ' ' +
        String(numberBase).substr(2, 1) +
        ' ' +
        String(numberBase).substr(3, 4) +
        '-' +
        String(numberBase).substr(7, 4)
      )
    case 10:
      return (
        '(' +
        String(numberBase).substr(0, 2) +
        ')' +
        ' ' +
        String(numberBase).substr(2, 4) +
        '-' +
        String(numberBase).substr(6, 4)
      )
    case 8:
      return (
        String(numberBase).substr(0, 4) + '-' + String(numberBase).substr(4, 4)
      )
  }
  return 'Número inválido'
})
Vue.filter('cpf-format', function (value) {
  let cpfBase = _.replace(value, /[^0-9]+/g, '')
  switch (cpfBase.length) {
    case 11:
    case 10:
      return (
        String(cpfBase).substr(0, 3) +
        '.' +
        String(cpfBase).substr(3, 3) +
        '.' +
        String(cpfBase).substr(6, 3) +
        '-' +
        String(cpfBase).substr(9, 2)
      )
  }

  if (cpfBase.length > 11) {
    let cnpj = cpfBase.replace(/\D/g, '')
    cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2')
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2')
    cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2')
    return cnpj
  }

  return 'Documento inválido'
})
Vue.filter('cnpj-format', function (value) {
  let cnpjBase = _.replace(value, /[^0-9]+/g, '')
  switch (cnpjBase.length) {
    case 14:
      return (
        String(cnpjBase).substr(0, 2) +
        '.' +
        String(cnpjBase).substr(2, 3) +
        '.' +
        String(cnpjBase).substr(5, 3) +
        '/' +
        String(cnpjBase).substr(8, 4) +
        '-' +
        String(cnpjBase).substr(12, 2)
      )
  }
  return 'CNPJ inválido'
})
Vue.filter('rg-format', function (value) {
  let rg = _.replace(value, /[^0-9]+/g, '')
  if (rg.length > 8) {
    return (
      String(rg).substr(0, 2) +
      '.' +
      String(rg).substr(2, 3) +
      '.' +
      String(rg).substr(5, 3) +
      '-' +
      String(rg).substr(8)
    )
  }
  return 'CPF inválido'
})
Vue.filter('cep-format', function (value) {
  let cep = _.replace(value, /[^0-9]+/g, '')
  if (cep.length == 8) {
    return String(cep).substr(0, 5) + '-' + String(cep).substr(5, 3)
  }
  return 'CEP inválido'
})
Vue.filter('phone', function (phone) {
  return phone
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
})
