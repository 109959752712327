import Vue from 'vue'
import Papa from 'papaparse'

// console.log(process.env);

Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
  validateStatus: () => true,
})
Vue.prototype.$plugins_ds = process.env.VUE_APP_PLUGINS

Vue.prototype.$core = axios.create({
  baseURL: process.env.VUE_APP_API_CORE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: () => true,
})

Vue.prototype.$validacoes = {
  add: (el) => {
    $(el)
      .find('[required-if]')
      .each(function () {
        var params = $(this).attr('required-if').split('|')
        if (params.length < 2) {
          return false
        }
        $(this).rules('add', {
          required: {
            depends: function (element) {
              if (params[0].indexOf('!') != -1) {
                return $(params[0].replace('!', '')).val() != params[1]
              }
              return $(params[0].replace('!', '')).val() == params[1]
            },
          },
        })
      })

    $(el)
      .find('[equal-to]')
      .each(function () {
        $(this).rules('add', {
          equalTo: $(this).attr('equal-to'),
        })
      })

    $(el)
      .find('[cpf-unique]')
      .each(function () {
        $(this).on('change', (event) => {
          var cpf = $(this).attr('data-value')

          let tipo =
            $(this).attr('cpf-unique') == 'afiliado'
              ? 'afiliados'
              : 'colaboradores'
          $(this).rules('add', {
            remote: {
              url: `${process.env.VUE_APP_API_URL}/${tipo}`,
              type: 'get',
              dataType: 'json',
              data: {
                cpf: cpf,
                bearer_token: vnode.context.$root.$cookie.get('authsession'),
              },
              dataFilter: function (data) {
                if (JSON.parse(data).total_itens == 0) {
                  return true
                }
                return false
              },
            },
            messages: {
              remote: 'CPF já cadastrado',
            },
          })
        })
      })

    $(el)
      .find('[validate-add]')
      .each(function () {
        var p = {}
        p[$(this).attr('validate-add')] = true

        $(this).rules('add', p)
      })
  },
}

Vue.prototype.$csv = (options) => {
  let csv = Papa.unparse(options.data, {
    header: true,
    worker: true,
    delimiter: options.delimiter != undefined ? options.delimiter : ';',
    download: options.download != undefined ? options.download : true,
  })

  let name = options.filename

  let blob = new Blob(['\ufeff', csv], { type: 'text/csv;charset=ISO-8859-1' })

  if (window.navigator.msSaveOrOpenBlob)
    // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    window.navigator.msSaveBlob(blob, name + '.csv')
  else {
    let a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob, {
      type: 'text/csv;charset=ISO-8859-1',
    })
    a.download = name + '.csv'
    document.body.appendChild(a)
    a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    document.body.removeChild(a)
  }
}

Vue.prototype.$graph = (options) => {
  let xAxis = []
  let legends = []
  let series = []
  let dataPizza = []

  var minLength = 0

  _.each(options.series, (s) => {
    if (_.get(options, 'legendas')) {
      legends.push(s.nome)
    }

    var data = []
    var total_serie = 0
    if (_.get(s, 'campo_grupo', null)) {
      let grupo = _.groupBy(s.data, (d) => {
        let campo
        if (typeof _.get(s, 'campo_grupo') == 'function') {
          campo = s.campo_grupo(d)
        } else {
          campo = _.get(d, _.get(s, 'campo_grupo'))
        }
        return campo
      })

      _.each(grupo, (g) => {
        let value = _.round(
          _.sumBy(g, (_d) =>
            parseFloat(_.get(_d, _.get(s, 'campo_valor'), null))
          ),
          2
        )
        total_serie += value
        data.push({
          value: value,
          originalData: g,
        })
      })
      _.each(Object.keys(grupo), (g) => {
        if (_.indexOf(xAxis, g) == -1) {
          xAxis.push(g)
        }
      })
    } else {
      _.each(s.data, (_d) => {
        let value = _.round(
          parseFloat(_.get(_d, _.get(s, 'campo_valor'), null)),
          2
        )
        total_serie += value
        data.push({
          value: value,
          originalData: _d,
        })
      })
    }

    if (_.get(data, 'length', 0) > minLength)
      minLength = _.get(data, 'length', 0)

    let tooltip = {
      formatter: (params) => {
        return `${params.seriesName}: R$ ${Number(params.value).toLocaleString(
          'pt-br',
          { minimumFractionDigits: 2 }
        )}`
      },
    }

    if (_.get(s, 'tooltip', null)) {
      tooltip = s.tooltip
    }
    series.push({
      name: s.nome,
      type: _.get(options, 'tipo', 'line'),
      data: data,
      stack: _.get(options, 'tipo', 'line') == 'line',
      tooltip: tooltip,
    })

    if (_.get(s, 'pizza', false) == true) {
      let tooltipPizza = {
        formatter: (params) => {
          return `${params.name}: R$ ${Number(params.value).toLocaleString(
            'pt-br',
            { minimumFractionDigits: 2 }
          )}`
        },
      }

      if (_.get(s, 'tooltipPizza')) {
        tooltipPizza = s.tooltipPizza
      }

      dataPizza.push({
        name: s.nome,
        value: total_serie,
        tooltip: tooltipPizza,
      })
    }
  })

  series.data = _.map(series, (s) => {
    if (_.get(s.data, 'length', 0) < minLength) {
      s.stack = false
      for (var i = 0; i < minLength; i++) {
        s.data.push({
          value: 0,
        })
      }
    }
    return s
  })

  if (minLength == 1 && options.tipo == 'line') {
    series = _.map(series, (s) => {
      s.type = 'bar'
      s.stack = false
      return s
    })
  }

  if (dataPizza) {
    let seriePizza = {
      type: 'pie',
      data: dataPizza,
      radius: _.get(options, 'pizza.raio', '20%'),
      center: [
        _.get(options, 'pizza.align.x', '80%'),
        _.get(options, 'pizza.align.y', '25%'),
      ],
      label: {
        normal: {
          show: true,
        },
      },
      z: 3,
    }

    if (_.get(options, 'pizza.legendas', null) == false) {
      seriePizza.label.normal.show = false
    }

    series.push(seriePizza)
  }

  let dataLegend = {
    data: legends,
    orient: _.get(options.legendas, 'orient', 'horizontal'),
    type: _.get(options.legendas, 'type', false),
  }

  if (_.get(options.legendas, 'left')) {
    dataLegend.left = options.legendas.left
  }

  if (_.get(options.legendas, 'right')) {
    dataLegend.right = options.legendas.right
  }

  if (_.get(options.legendas, 'top')) {
    dataLegend.top = options.legendas.top
  }

  return {
    title: false,
    tooltip: {},
    legend: dataLegend,
    xAxis: {
      type: 'category',
      data: xAxis,
    },
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: series,
    calculable: true,
  }
}
