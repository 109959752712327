<template lang="html">
  <li class="nav-item text-white" v-show="visible">
    <a
      href
      @click.prevent="toggleCollapse"
      class="nav-link"
      v-if="childrens && childrens.length > 0"
    >
      <div class="row align-items-center">
        <div class="col">
          <i :class="item.icon" class="mr-2 text-white-opacity-200"></i>
          {{ item.label }}
        </div>
        <div class="col-auto">
          <small
            ><i
              class="fa"
              :class="{
                'fa-angle-right': !collapsed,
                'fa-angle-down': collapsed,
              }"
            ></i
          ></small>
        </div>
      </div>
    </a>
    <router-link
      v-if="!childrens || childrens.length == 0"
      class="nav-link"
      :to="{ name: item.name, params: item.params ? item.params : {} }"
      active-class="active"
      exact
    >
      <div class="row align-items-center">
        <div class="col">
          <i :class="item.icon" class="mr-2 text-white-opacity-200"></i>
          {{ item.label }}
        </div>
        <div class="col-auto" v-if="itemIsLock == true">
          <i class="fa fa-lock float-right"></i>
        </div>
      </div>
    </router-link>
    <p-menu
      class="ml-4"
      v-show="childrens && childrens.length > 0 && collapsed"
      :menu="childrens"
    ></p-menu>
  </li>
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      visible: true,
      collapsed: false,
      childrensReject: [],
    }
  },
  computed: {
    itemIsLock() {
      if (this.item.permissions == undefined) return false

      let autorizacao = _.find(this.$root.cliente.autorizacoes, (a) => {
        return a.chave == this.item.permissions.key
      })

      if (!autorizacao || autorizacao.status != 'liberado') return true

      return false
    },
    childrens() {
      let routes = _.find(this.$router.options.routes, (r) => {
        return r.auth != undefined
      })
      return _.filter(routes.children, (children) => {
        return (
          children.parent != undefined &&
          children.parent == this.item.name &&
          (children.menu == undefined || children.menu == true)
        )
      })
    },
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed

      if (this.item.parent == undefined)
        this.$root.$emit('menu.collapsed', this)
    },
  },
  created() {
    if (
      this.item.parent != undefined &&
      !this.$root.checkPermissionItemMenu(this.item)
    ) {
      this.visible = false
      this.$root.$emit('menu.reject', {
        parent: this.item.parent,
        item: this.parent,
      })
    } else if (
      this.item.parent == undefined &&
      this.childrens.length == 0 &&
      !this.$root.checkPermissionItemMenu(this.item)
    ) {
      this.visible = false
    }
    this.$root.$on('menu.reject', (target) => {
      if (target.parent == this.item.name) this.childrensReject.push(this.item)

      if (
        (this.childrensReject.length > 0) &
        (this.childrensReject.length == this.childrens.length)
      ) {
        this.visible = false
      }
    })
    this.$root.$on('menu.collapsed', (target) => {
      if (target._uid != this._uid && this.item.parent == undefined) {
        this.collapsed = false
      }
    })
  },
}
</script>
<style lang="scss" scoped>
@import '../../../scss/variables.scss';
a {
  color: white;
  &:hover {
    color: theme-color('primary');
  }
}
</style>
