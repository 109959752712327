import { render, staticRenderFns } from "./MenuItem.vue?vue&type=template&id=73715f54&scoped=true&lang=html"
import script from "./MenuItem.vue?vue&type=script&lang=js"
export * from "./MenuItem.vue?vue&type=script&lang=js"
import style0 from "./MenuItem.vue?vue&type=style&index=0&id=73715f54&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73715f54",
  null
  
)

export default component.exports